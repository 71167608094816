// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  display: block;
  box-shadow: none !important;
  border: 0;
  border-top: 1px solid $color-grey-3;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &::before {
    content: none;
  }

  &:last-of-type {
    border-bottom: 1px solid $color-grey-3;
  }

  .header {
    padding: 1.5em 1em;
  }

  h3 {
    &.label {
      font-size: 1.25em;
      display: flex;
      padding-right: 1em;
      font-family: $font-family-serif;
      font-weight: 500;
      align-items: center;

      @include desktop {
        font-size: 1.625em;
      }
    }
  }

  .arrow {
    width: 40px;
    height: 40px;
    color: $color-black;
  }

  .label {
    position: relative;
    cursor: pointer;

    svg {
      position: absolute;
      right: .5em;
      display: inline-block;
      width: .6875em;
      height: .875em;
      transition-duration: $transition-duration-fast;
      transition-property: transform;
    }
  }

}
