// --------------- Colors (Private Variables) ----------------
// Orange
$color-orange:           #df6541;
$color-orange-dark:      #cc5e3d;

// Sand
$color-sand-very-light:  #d8c19e;
$color-sand-light:       #c99e5e;
$color-sand:             #b38e57;
$color-sand-dark:        #a4814f;

// Beige
$color-beige:            #fcf9f1;

// Blue
$color-blue:             #4696c4;
$color-blue-10:          rgba(70, 150, 196, .1);
$color-blue-light:       #edf3f6;
$color-blue-dark:        #31587f;
$color-dark-navy-bg:     #f3f4f4;
$color-blue-utility:     #1369d2;
$color-dark-navy-light:  #6d6f7c;
$color-dark-navy-20:     #cecfd3;
$color-dark-navy-80:     #3d3f50;
$color-dark-navy:        #0c0f24;
$color-dark-navy-10:     #e8edf5;

// Blue-Grey
$color-blue-grey-1:      #eeeff1;
$color-blue-grey-2:      #858998;
$color-blue-grey-23:     #636670;
$color-blue-grey-3:      #5a5c65;
$color-blue-grey-4:      #2f323b;
$color-blue-grey-5:      #1d1f28;

// Red
$color-red:              #c6483a;
$color-red-light:        #fcf1f1;

// Yellow
$color-yellow:           #df9628;

// Green
$color-green:            #84a338;
$color-green-bright:     #0fc282;

// White
$color-white:            #fff;
$color-white-10:         rgba($color-white, .1);
$color-white-20:         rgba($color-white, .2);
$color-white-25:         rgba($color-white, .25);
$color-white-50:         rgba($color-white, .5);
$color-white-80:         rgba($color-white, .8);

// Blacks
$color-black:            #000;
$color-black-05:         rgba($color-black, .05);
$color-black-10:         rgba($color-black, .1);
$color-black-15:         rgba($color-black, .15);
$color-black-20:         rgba($color-black, .2);
$color-black-25:         rgba($color-black, .25);
$color-black-35:         rgba($color-black, .35);
$color-black-40:         rgba($color-black, .4);
$color-black-50:         rgba($color-black, .5);
$color-black-65:         rgba($color-black, .65);
$color-black-75:         rgba($color-black, .75);
$color-black-80:         rgba($color-black, .8);
$color-black-90:         rgba($color-black, .9);
$color-warm-black:       #191B1E;
$color-warm-black-5:     #F3F4F4;
$color-warm-black-10:    #E8E8E8;
$color-warm-black-20:    #D1D1D2;
$color-warm-black-60:    #757678;
$color-warm-black-80:    #47494B;

// Greys
$color-grey-1:           #efefef;
$color-grey-2:           #ececec;
$color-grey-3:           #dedede;
$color-grey-4:           #dbd7d6;
$color-grey-5:           #b6b5b5;
$color-grey-6:           #191b1e;
$color-grey-7:           #f6f4f4;
$color-grey-8:           #393939;
$color-grey-9:           #46484a;
$color-grey-10:          #181b1e;
$color-grey-11:          #fcfcfc;
$color-grey-12:          #9f9f9f;
$color-grey-13:          #f3f3f3;
$color-grey-14:          #cdcdcd;
$color-grey-15:          #f9f9f9;
$color-grey-16:          #e7e7e9;
$color-grey-17:          #47494b;
$color-grey-18:          #6d6f7c;
$color-grey-19:          #f6f6f5;
$color-grey-20:          #9e9fa7;
$color-grayish-blue:     #d1d1d1;
$color-grey-light:       rgba(255, 255, 255, 0.1);
$color-grey-light-2:     rgba(255, 255, 255, 0.2);
$color-grey-super-light: rgba(243, 243, 244, 0.50);

// Silver
$color-modern-silver:      #5a5a5a;
$color-modern-silver-light: #8e9195;

// Transparent
$color-transparent:      rgba($color-white, 0);

// Exp Theme Colors
$color-exp-primary-brand-royal:     #1b489b;
$color-exp-primary-brand-royal-80:  #496daf;
$color-exp-secondary-dark-navy: #0c0f24;
$color-exp-secondary-dark-navy-5: #f3f3f4;
$color-exp-secondary-dark-navy-80: #3d3f50;
$color-exp-maroon: #6d071a;


// Specific Use Cases
$color-map-background:   #f8f5ed;
$color-visited-map-pin:  #757678;
$color-box-shadow:  0 1px 10px rgba(0, 0, 0, .04), 0 4px 5px rgba(0, 0, 0, .06), 0 2px 4px -1px rgba(0, 0, 0, .09);
$color-snackbar-bg:     #e5efeb;
$color-snackbar-bg-error: #f1dada;
$color-snackbar-text:   #0d4d36;
$color-snackbar-text-error:   #4d0d0d;
$callout-background: rgba(247, 247, 247, .749);
$color-arrow-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .18);
$color-listing-detail-label: #858791;
$color-exp-keywords-bg: #e9edf2;
$color-commercial-footer-bg: #1c1e2a;

// Gradients
$color-modern-silver-gradient: linear-gradient(270deg, $color-modern-silver -125.65%, $color-modern-silver-light 89.64%); // sass-lint:disable-line no-color-literals
